<template>
  <div>

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10">
        <h3 class="font-size-h1">Forget Password</h3>
      </div>

      <!--begin::Form - Login -->
      <b-form class="form reset-pass" @submit.stop.prevent="onSubmit" autocomplete="nope">
        <div
          v-if="errors" 
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
            autocomplete="nope"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
            placeholder="Password"
            autocomplete="nope"
          ></b-form-input>

          <b-form-invalid-feedback id="input-3-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label=""
          label-for="example-input-3"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-3"
            name="example-input-3"
            v-model="$v.form.c_password.$model"
            :state="validateState('c_password')"
            aria-describedby="input-3-live-feedback"
            placeholder="Confirm Password"
            autocomplete="nope"
          ></b-form-input>

          <b-form-invalid-feedback id="input-3-live-feedback">
            Confirm password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            @click="gotoLogin()" 
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Sign In?
          </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Submit
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, required, sameAs } from "vuelidate/lib/validators";
import Form from '@/core/services/api.form.services';

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: new Form({
        token: null,
        email:null,
        password:null,
        c_password:null
      }),
      api_url:'password/reset'
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password:{
        required
      },
      c_password:{
        required,
        data: { 
          sameAsPassword: sameAs(function() {
            return this.form.password.data;
          }) 
        },
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      //const email = this.$v.form.email.$model;
      this.loading=true;
      this.form.post(this.api_url).then((response) => {
        if(response.status){
          this.$showResponse('success', response.message);
          this.gotoLogin();
        }else{
          this.errors
        }
      })
    },
    gotoLogin(){
      this.$router.push({ path: '/login' })
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>

<style>
.login-text-css {
    background-color: #00163b;
    border-color: #00163b;
    color: #ffffff;
}
</style>
